import { AppConfig } from '../../config/app.config';
import { APP_CONFIG } from '../../config/app.config';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AccountType,
  AuthProvider,
  FrontparentService,
  FrontbabysitterService,
} from '@tatynanny/api';
import { AlertService } from '../alert/alert.service';
import * as moment from 'moment';
import { EnvService } from '../environment/env.service';
import { OAuthProviderList } from './interfaces/oauth-provider';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class KeycloakSocialLoginService {
  private user = new Subject<any>();

  private userType: AccountType = AccountType.Client;

  providersApis: OAuthProviderList = {
    google: {
      url: 'https://people.googleapis.com/v1/people/me',
      params:
        'addresses,ageRanges,biographies,birthdays,calendarUrls,clientData,coverPhotos,emailAddresses,events,externalIds,genders,imClients,interests,locales,locations,memberships,metadata,miscKeywords,names,nicknames,occupations,organizations,phoneNumbers,photos,relations,sipAddresses,skills,urls,userDefined',
    },
    facebook: {
      url: 'https://graph.facebook.com/me',
      params:
        'id,name,email,gender,location,address,hometown,locale,picture,birthday,age_range,cover,devices,first_name,last_name,verified,languages,link,sports,timezone',
    },
  };

  constructor(
    private alertService: AlertService,
    private parentService: FrontparentService,
    private babysitterService: FrontbabysitterService,
    private router: Router,
    private envService: EnvService,
    private http: HttpClient,
    @Inject(APP_CONFIG) config: AppConfig,
    private platform: Platform
  ) {
    this.userType = config.userType;
  }

  sendUser(user: any) {
    console.log('Send User :', user);
    this.user.next(user);
  }

  getUser(): Observable<any> {
    console.log('Get user');
    return this.user.asObservable();
  }

  redirectOauthPage(identityProvider: AuthProvider) {
    if (
      [AuthProvider.Google, AuthProvider.Facebook].includes(identityProvider)
    ) {
      const type = identityProvider.toLowerCase();
      const keycloakUrl = `${this.envService.keycloak.url}`;
      const providerUrl = `realms/${this.envService.keycloak.realm}/protocol/openid-connect/auth?client_id=${this.envService.keycloak.clientId}`;
      let redirectBaseUri = '';
      if (this.platform.platforms().includes('mobile')) {
        redirectBaseUri = this.envService.keycloak.redirectUri;
      } else {
        redirectBaseUri = this.envService.keycloak.redirectUriBrowser;
      }
      const redirectUri = `${redirectBaseUri}/home/register`;
      const params = `?kc_idp_hint=${type}&response_type=code&scope=openid&kc_idp_hint=${type}`;
      const url = `${keycloakUrl}/${providerUrl}&redirect_uri=${redirectUri}${params}`;

      console.log('URL:', url);
      window.location.href = url;
    }
  }

  saveGoogleInfo(userGoogle: any, generalAccount: any, newAccount: any): void {
    if (userGoogle) {
      if (userGoogle.genders?.[0].value == 'male') {
        generalAccount.civilite = 1;
      } else if (userGoogle.genders?.[0].value == 'female') {
        generalAccount.civilite = 2;
      }
      if (userGoogle.addresses) {
        generalAccount.formattedAddress =
          userGoogle.addresses[0].formattedValue;
        generalAccount.address = userGoogle.addresses[0].streetAddress;
        generalAccount.city = userGoogle.addresses[0].city;
        generalAccount.zipcode = userGoogle.addresses[0].postalCode;
        generalAccount.country = userGoogle.addresses[0].country;
      } else {
        generalAccount.formattedAddress = '';
      }
      if (userGoogle.birthdays) {
        if (userGoogle.birthdays[0].date.day.toString().length == 1) {
          userGoogle.birthdays[0].date.day =
            '0' + userGoogle.birthdays[0].date.day.toString();
        }
        generalAccount.birthdate = `${userGoogle.birthdays[0].date.year.toString()}-${userGoogle.birthdays[0].date.month.toString()}-${
          userGoogle.birthdays[0].date.day
        }`;
      } else {
        generalAccount.birthdate = `1970-01-01`;
      }
      generalAccount.account.firstname = newAccount.firstname;
      generalAccount.account.lastname = newAccount.lastname;
      generalAccount.account.phoneNumber = newAccount.phoneNumber;

      if (this.userType == AccountType.Client) {
        this.parentService
          .saveGeneralInformations(generalAccount)
          .forEach((r) => {
            console.log(r);
            this.alertService.temp('Informations enregistrées', 1000);
            this.router.navigateByUrl('/board/profile', {
              replaceUrl: true,
            });
          })
          .catch((e) => {
            console.log(e);
            this.alertService.temp('ERROR API', 2000);
          });
      } else if (this.userType == AccountType.Babysitter) {
        this.babysitterService
          .saveGeneralBabysit(generalAccount)
          .forEach((r) => {
            console.log(r);
            this.alertService.temp('Informations enregistrées', 1000);
            this.router.navigateByUrl('/board/profile', {
              replaceUrl: true,
            });
          }).catch((e) => {
            console.log(e);
            this.alertService.temp('ERROR API', 2000);
          });
      }
    }
  }

  saveFacebookInfo(
    userFacebook: any,
    generalAccount: any,
    newAccount: any
  ): void {
    if (userFacebook) {
      if (userFacebook.gender == 'male') {
        generalAccount.civilite = 1;
      } else if (userFacebook.gender == 'female') {
        generalAccount.civilite = 2;
      }
      generalAccount.birthdate = userFacebook.birthday
        ? moment(userFacebook.birthday).format('YYYY-MM-DD')
        : '1970-01-01';

      if (userFacebook.location) {
        generalAccount.formattedAddress = userFacebook.location.name;
      } else {
        generalAccount.formattedAddress = '';
      }
      generalAccount.account.firstname = newAccount.firstname;
      generalAccount.account.lastname = newAccount.lastname;
      generalAccount.account.phoneNumber = newAccount.phoneNumber;

      if (this.userType == AccountType.Client) {
        this.parentService
          .saveGeneralInformations(generalAccount)
          .forEach((r) => {
            console.log(r);
            this.alertService.temp('Informations enregistrées', 1000);
            this.router.navigateByUrl('/board/profile', {
              replaceUrl: true,
            });
          })
          .catch((e) => {
            console.log(e);
            this.alertService.temp('ERROR API', 2000);
          });
      } else if (this.userType == AccountType.Babysitter) {
        this.babysitterService
          .saveGeneralBabysit(generalAccount)
          .forEach((r) => {
            console.log(r);
            this.alertService.temp('Informations enregistrées', 1000);
            this.router.navigateByUrl('/board/profile', {
              replaceUrl: true,
            });
          }).catch((e) => {
            console.log(e);
            this.alertService.temp('ERROR API', 2000);
          });
      }
    }
  }

  socialProviderInfo(
    provider: any,
    instance: Keycloak.KeycloakInstance
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${this.envService.keycloak.url}/realms/${this.envService.keycloak.realm}/broker/${provider}/token`,
          {
            headers: {
              Authorization: 'Bearer ' + instance.token,
            },
          }
        )
        .toPromise()
        .then((providerToken: any) => {
          var myHeaders = new Headers();
          myHeaders.append(
            'Authorization',
            'Bearer ' + providerToken['access_token']
          );

          let url = '';
          let providerApi = this.providersApis[provider];
          if (provider == 'google') {
            url = `${providerApi.url}?personFields=${providerApi.params}`;
          } else if (provider == 'facebook') {
            url = `${providerApi.url}?fields=${providerApi.params}&access_token=${providerToken['access_token']}`;
          }

          fetch(url, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          })
            .then((response) => resolve(response.json()))
            .catch((error) => console.log('error', error));
        });
    });
  }
}
