import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from './services/guard/auth-guard.service';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home/:id',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'babysitter',
    loadChildren: () => import('./app-mobile/babysitter/babysitter.module').then( m => m.BabysitterPageModule)
  },
  {
    path: 'board',
    loadChildren: () => import('./connected/board/board.module').then( m => m.BoardPageModule)
  },
  {
    path: 'app-board',
    loadChildren: () => import('./connected/app-board/app-board.module').then( m => m.AppBoardPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'research',
    loadChildren: () => import('./research/research.module').then( m => m.ResearchPageModule)
  },
  {
    path: 'home/register/validate',
    loadChildren: () => import('./components/home-components/register/validate/validate.module').then( m => m.ValidatePageModule)
  },
  {
    path: 'register-social',
    loadChildren: () => import('./components/home-components/register/register-social/register-social.module').then( m => m.RegisterSocialPageModule)
  },
  {
    path: 'register-social/:id',
    loadChildren: () => import('./components/home-components/register/register-social/register-social.module').then( m => m.RegisterSocialPageModule)
  },
  {
    path: 'home/validate/validate-fin',
    loadChildren: () => import('./components/home-components/register/validate-fin/validate-fin.module').then( m => m.ValidateFinModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
