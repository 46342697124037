import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {from, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import * as Keycloak_ from 'keycloak-ionic';
import {
  ExcludedUrl,
  ExcludedUrlRegex,
  KeycloakOptions,
} from './interfaces/keycloak-options';
import {KeycloakEvent, KeycloakEventType} from './interfaces/keycloak-event';
import {Preferences} from '@capacitor/preferences';
import {Platform} from '@ionic/angular';
import {EnvService} from '../environment/env.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedService} from '../shared/shared.service';
import {KeycloakSocialLoginService} from './keycloak-social-login.service';
import {SecurityService} from '@tatynanny/api';
import {AlertService} from '../alert/alert.service';

export const Keycloak = Keycloak_;
declare const gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class KeycloakIonicService {

  constructor(
    private platform: Platform,
    private envService: EnvService,
    private sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private keycloakSocialLoginService: KeycloakSocialLoginService,
    private securityService: SecurityService,
    private alertService: AlertService,
  ) {
  }

  /**
   * Keycloak-js instance.
   */
  private _instance: Keycloak.KeycloakInstance;
  /**
   * User profile as KeycloakProfile interface.
   */
  private _userProfile: Keycloak.KeycloakProfile;
  /**
   * Flag to indicate if the bearer will not be added to the authorization header.
   */
  private _enableBearerInterceptor: boolean;
  /**
   * When the implicit flow is choosen there must exist a silentRefresh, as there is
   * no refresh token.
   */
  private _silentRefresh: boolean;
  /**
   * Indicates that the user profile should be loaded at the keycloak initialization,
   * just after the login.
   */
  private _loadUserProfileAtStartUp: boolean;
  /**
   * The bearer prefix that will be appended to the Authorization Header.
   */
  private _bearerPrefix: string;
  /**
   * Value that will be used as the Authorization Http Header name.
   */
  private _authorizationHeaderName: string;
  /**
   * The excluded urls patterns that must skip the KeycloakBearerInterceptor.
   */
  private _excludedUrls: ExcludedUrlRegex[];
  /**
   * Observer for the keycloak events
   */
  private _keycloakEvents$: Subject<KeycloakEvent> =
    new Subject<KeycloakEvent>();

  /**
   * Binds the keycloak-js events to the keycloakEvents Subject
   * which is a good way to monitor for changes, if needed.
   *
   * The keycloakEvents returns the keycloak-js event type and any
   * argument if the source function provides any.
   */
  private bindsKeycloakEvents(): void {
    this._instance.onAuthError = (errorData) => {
      this._keycloakEvents$.next({
        args: errorData,
        type: KeycloakEventType.OnAuthError,
      });
    };

    this._instance.onAuthLogout = () => {
      this.sharedService.sendRefreshMenu();

      // this.router.navigateByUrl('/home/tatynanny', {
      //   replaceUrl: true,
      // });
      this._keycloakEvents$.next({type: KeycloakEventType.OnAuthLogout});
    };

    this._instance.onAuthRefreshSuccess = () => {

      this._keycloakEvents$.next({
        type: KeycloakEventType.OnAuthRefreshSuccess,
      });
    };

    this._instance.onAuthRefreshError = () => {

      this._keycloakEvents$.next({
        type: KeycloakEventType.OnAuthRefreshError,
      });
    };

    this._instance.onAuthSuccess = async () => {
      this.sharedService.sendRefreshMenu();
      this._instance.token = await this.getToken();
      // let provider = this.route.snapshot.queryParamMap.get('kc_idp_hint');
      // if (provider == null) {
      //   if (this.platform.platforms().includes('mobile') && !this.platform.platforms().includes('mobileweb')) {
      //     this.router.navigateByUrl('/app-board')
      //   } else {
      //     this.router.navigateByUrl('/mon-espace/tableau-de-bord', {
      //       replaceUrl: true,
      //     });
      //   }
      // }

      this._keycloakEvents$.next({type: KeycloakEventType.OnAuthSuccess});
    };

    this._instance.onTokenExpired = () => {

      this._keycloakEvents$.next({
        type: KeycloakEventType.OnTokenExpired,
      });
    };

    this._instance.onReady = (authenticated) => {
      this._keycloakEvents$.next({
        args: authenticated,
        type: KeycloakEventType.OnReady,
      });
      if (authenticated) {
        this.securityService.apiV1SecurityLoadGet().subscribe(security => {
          if (security.result.userType == 'CLIENT') {
            this.alertService.ask('Compte existant', `Votre compte est déjà enregistrer en tant que parent. Voulez-vous être rediriger vers www.tatynannyjobs.com ?`, 'Oui, rediriger', () => {
              window.location.href = `${this.envService.frontClientUrl}`;
            }, 'Se déconnecter', () => {
              this.logout();
            })
          } else {
            if (this.platform.platforms().includes('mobile') && !this.platform.platforms().includes('mobileweb')) {
              this.router.navigateByUrl('/app-board', {
                replaceUrl: true,
              })
            } else {
              this.router.navigateByUrl('/board/dashboard', {
                replaceUrl: true,
              });
            }
          }
        })
      } else {
        if (this.platform.platforms().includes('mobile') && !this.platform.platforms().includes('mobileweb')) {
          this.router.navigateByUrl('/home/starter', {
            replaceUrl: true
          })
        } else {
          if (!this.router.url.includes('demande-de-devis')) {
            this.router.navigateByUrl('/home');
          }
        }
      }

    };
  }

  /**
   * Loads all bearerExcludedUrl content in a uniform type: ExcludedUrl,
   * so it becomes easier to handle.
   *
   * @param bearerExcludedUrls array of strings or ExcludedUrl that includes
   * the url and HttpMethod.
   */
  private loadExcludedUrls(
    bearerExcludedUrls: (string | ExcludedUrl)[]
  ): ExcludedUrlRegex[] {
    const excludedUrls: ExcludedUrlRegex[] = [];
    for (const item of bearerExcludedUrls) {
      let excludedUrl: ExcludedUrlRegex;
      if (typeof item === 'string') {
        excludedUrl = {urlPattern: new RegExp(item, 'i'), httpMethods: []};
      } else {
        excludedUrl = {
          urlPattern: new RegExp(item.url, 'i'),
          httpMethods: item.httpMethods,
        };
      }
      excludedUrls.push(excludedUrl);
    }
    return excludedUrls;
  }

  /**
   * Handles the class values initialization.
   *
   * @param options
   */
  private initServiceValues({
    enableBearerInterceptor = true,
    loadUserProfileAtStartUp = false,
    bearerExcludedUrls = [],
    authorizationHeaderName = 'Authorization',
    bearerPrefix = 'Bearer',
    initOptions,
  }: KeycloakOptions): void {
    this._enableBearerInterceptor = enableBearerInterceptor;
    this._loadUserProfileAtStartUp = loadUserProfileAtStartUp;
    this._authorizationHeaderName = authorizationHeaderName;
    this._bearerPrefix = bearerPrefix.trim().concat(' ');
    this._excludedUrls = this.loadExcludedUrls(bearerExcludedUrls);
    this._silentRefresh = initOptions ? initOptions.flow === 'implicit' : false;
  }

  /**
   * Keycloak initialization. It should be called to initialize the adapter.
   * Options is a object with 2 main parameters: config and initOptions. The first one
   * will be used to create the Keycloak instance. The second one are options to initialize the
   * keycloak instance.
   *
   * @param options
   * Config: may be a string representing the keycloak URI or an object with the
   * following content:
   * - url: Keycloak json URL
   * - realm: realm name
   * - clientId: client id
   *
   * initOptions:
   * Options to initialize the Keycloak adapter, matches the options as provided by Keycloak itself.
   *
   * enableBearerInterceptor:
   * Flag to indicate if the bearer will added to the authorization header.
   *
   * loadUserProfileInStartUp:
   * Indicates that the user profile should be loaded at the keycloak initialization,
   * just after the login.
   *
   * bearerExcludedUrls:
   * String Array to exclude the urls that should not have the Authorization Header automatically
   * added.
   *
   * authorizationHeaderName:
   * This value will be used as the Authorization Http Header name.
   *
   * bearerPrefix:
   * This value will be included in the Authorization Http Header param.
   *
   * @returns
   * A Promise with a boolean indicating if the initialization was successful.
   */
  public async init(options: KeycloakOptions = {}) {
    console.log('FUNCTION INIT', options);
    this.initServiceValues(options);
    const {config, initOptions} = options;

    this._instance = Keycloak(config);
    this.bindsKeycloakEvents();
    // this.loadUserProfile();
    this.setLoginSubscriptions('login');

    const authenticated = await this._instance.init(initOptions);
    console.log('authenticated', authenticated);

    if (authenticated && this._loadUserProfileAtStartUp) {
      await this.loadUserProfile();
    }

    return authenticated;
  }

  public setLoginSubscriptions(source: string): void {
    console.log('[keycloak] subscribing on ' + source);
    const sub = this._keycloakEvents$.subscribe((val) => {
      console.log('[keycloak event]: ', val);

      if (val.type == KeycloakEventType.OnAuthSuccess) {
        console.log('[keycloak success]', this._instance.token);
        console.log('[keycloak refresh success]', this._instance.refreshToken);

        if (this._instance.token) {
          this.sharedService.sendToken(this._instance.token);
        }
        try {

          let kcTokens = {
            accessToken: this._instance.token,
            refreshToken: this._instance.refreshToken,
            idToken: this._instance.idToken
          }


          Preferences.set({key: 'kcTokens', value: JSON.stringify(kcTokens)}).then(
            () => console.log('[keycloak success] KC TOKENS Stored!'),
            (error) =>
              console.error('[keycloak success] Error storing item', error)
          );


          let provider = this.route.snapshot.queryParamMap.get('kc_idp_hint');
          if (provider != null) {
            this.keycloakSocialLoginService.socialProviderInfo(provider, this._instance).then((res: any) => {
              this.keycloakSocialLoginService.sendUser(res);
            })
          }
        } catch (e) {
          console.log('[keycloak success] error while seting token', e);
        }
      }
      if (val.type == KeycloakEventType.OnAuthError) {
        console.log('[keycloak auth error]');
        try {
          Preferences.remove({key: 'kcTokens'}).then(
            (va) => {
              console.log('[keycloak auth error]. removed tokens from Preferences');
            },
            (err) => {
              console.log(
                '[keycloak auth error]. failed to remove token from Preferences'
              );
            }
          );
        } catch (e) {
          console.log('[keycloak auth error] error while removing tokens');
        }
      }
      if (val.type == KeycloakEventType.OnAuthLogout) {
        console.log('[keycloak logout]. removing token from Preferences');
        try {
          Preferences.remove({key: 'kcTokens'}).then(
            () => {
              console.log('[keycloak logout]. removed tokens from Preferences');
            },
            (err) => {
              console.log(
                '[keycloak logout]. failed to remove tokens from Preferences'
              );
            }
          );
        } catch (e) {
          console.log('error while removing tokens');
        }
      }
    });
  }

  public async socialLogin(options: Keycloak.KeycloakLoginOptions = {}) {
    const login = await this._instance.login(options);
    const profile = await this.loadUserProfile();
    console.log({profile});
    return profile;
  }


  /**
   * Redirects to login form on (options is an optional object with redirectUri and/or
   * prompt fields).
   *
   * @param options
   * Object, where:
   *  - redirectUri: Specifies the uri to redirect to after login.
   *  - prompt:By default the login screen is displayed if the user is not logged-in to Keycloak.
   * To only authenticate to the application if the user is already logged-in and not display the
   * login page if the user is not logged-in, set this option to none. To always require
   * re-authentication and ignore SSO, set this option to login .
   *  - maxAge: Used just if user is already authenticated. Specifies maximum time since the
   * authentication of user happened. If user is already authenticated for longer time than
   * maxAge, the SSO is ignored and he will need to re-authenticate again.
   *  - loginHint: Used to pre-fill the username/email field on the login form.
   *  - action: If value is 'register' then user is redirected to registration page, otherwise to
   * login page.
   *  - locale: Specifies the desired locale for the UI.
   * @returns
   * A void Promise if the login is successful and after the user profile loading.
   */
  public async login(options: Keycloak.KeycloakLoginOptions = {}) {
    //this.alertService.showSpinner().subscribe(() => {

    this._instance.login(options).then(() => {
      if (this._loadUserProfileAtStartUp) {
        this.loadUserProfile();
      }
      //      this.alertService.dismissALlPending();
    });
    //});
  }

  /**
   * Redirects to logout.
   *
   * @param redirectUri
   * Specifies the uri to redirect to after logout.
   * @returns
   * A void Promise if the logout was successful, cleaning also the userProfile.
   */
  public async logout(redirectUri?: string) {
    if (this.platform.platforms().includes('mobile')) {
      redirectUri = this.envService.keycloak.redirectUri;
    } else {
      redirectUri = this.envService.keycloak.redirectUriBrowser;
    }
    const options = {
      redirectUri,
    };
    this._userProfile = undefined;

    var url = this._instance.authServerUrl + '/realms/' + this._instance.realm + '/protocol/openid-connect/logout'
      + '?post_logout_redirect_uri=' + encodeURIComponent(redirectUri)
      + '&id_token_hint=' + encodeURIComponent(this._instance.idToken);
    console.log('logout url: ', url);
    window.location.href = url;

    return;
  }


  /**
   * Redirects to registration form. Shortcut for login with option
   * action = 'register'. Options are same as for the login method but 'action' is set to
   * 'register'.
   *
   * @param options
   * login options
   * @returns
   * A void Promise if the register flow was successful.
   */
  public async register(
    options: Keycloak.KeycloakLoginOptions = {action: 'register'}
  ) {
    await this._instance.register(options);
  }

  /**
   * Check if the user has access to the specified role. It will look for roles in
   * realm and clientId, but will not check if the user is logged in for better performance.
   *
   * @param role
   * role name
   * @param resource
   * resource name If not specified, `clientId` is used
   * @returns
   * A boolean meaning if the user has the specified Role.
   */
  isUserInRole(role: string, resource?: string): boolean {
    let hasRole: boolean;
    hasRole = this._instance.hasResourceRole(role, resource);
    if (!hasRole) {
      hasRole = this._instance.hasRealmRole(role);
    }
    return hasRole;
  }

  /**
   * Return the roles of the logged user. The allRoles parameter, with default value
   * true, will return the clientId and realm roles associated with the logged user. If set to false
   * it will only return the user roles associated with the clientId.
   *
   * @param allRoles
   * Flag to set if all roles should be returned.(Optional: default value is true)
   * @returns
   * Array of Roles associated with the logged user.
   */
  getUserRoles(allRoles: boolean = true): string[] {
    let roles: string[] = [];
    if (this._instance.resourceAccess) {
      for (const key in this._instance.resourceAccess) {
        if (this._instance.resourceAccess.hasOwnProperty(key)) {
          const resourceAccess: any = this._instance.resourceAccess[key];
          const clientRoles = resourceAccess['roles'] || [];
          roles = roles.concat(clientRoles);
        }
      }
    }
    if (allRoles && this._instance.realmAccess) {
      const realmRoles = this._instance.realmAccess['roles'] || [];
      roles.push(...realmRoles);
    }
    return roles;
  }

  /**
   * Check if user is logged in.
   *
   * @returns
   * A boolean that indicates if the user is logged in.
   */
  async isLoggedIn(): Promise<boolean> {
    try {
      if (!this._instance.authenticated) {
        return false;
      }
      try {
        await this.updateToken(20);
      } catch (e) {
        console.log('[keycloak js] isLoggedIn error. update token error', e);
      }
      return true;
    } catch (error) {
      console.log('[keycloak js] isLoggedIn error', error);
      return false;
    }
  }

  /**
   * Returns true if the token has less than minValidity seconds left before
   * it expires.
   *
   * @param minValidity
   * Seconds left. (minValidity) is optional. Default value is 0.
   * @returns
   * Boolean indicating if the token is expired.
   */
  isTokenExpired(minValidity: number = 0): boolean {
    return this._instance.isTokenExpired(minValidity);
  }

  /**
   * If the token expires within minValidity seconds the token is refreshed. If the
   * session status iframe is enabled, the session status is also checked.
   * Returns a promise telling if the token was refreshed or not. If the session is not active
   * anymore, the promise is rejected.
   *
   * @param minValidity
   * Seconds left. (minValidity is optional, if not specified 5 is used)
   * @returns
   * Promise with a boolean indicating if the token was succesfully updated.
   */
  public async updateToken(minValidity = 5) {
    // TODO: this is a workaround until the silent refresh (issue #43)
    // is not implemented, avoiding the redirect loop.
    if (this._silentRefresh) {
      if (this.isTokenExpired()) {
        throw new Error(
          'Failed to refresh the token, or the session is expired'
        );
      }

      return true;
    }

    if (!this._instance) {
      throw new Error('Keycloak Angular library is not initialized.');
    }

    return this._instance.updateToken(minValidity);
  }

  /**
   * Loads the user profile.
   * Returns promise to set functions to be invoked if the profile was loaded
   * successfully, or if the profile could not be loaded.
   *
   * @param forceReload
   * If true will force the loadUserProfile even if its already loaded.
   * @returns
   * A promise with the KeycloakProfile data loaded.
   */
  public async loadUserProfile(forceReload = false) {
    if (this._userProfile && !forceReload) {
      return this._userProfile;
    }

    if (!this._instance.authenticated) {
      throw new Error(
        'The user profile was not loaded as the user is not logged in.'
      );
    }

    return (this._userProfile = await this._instance.loadUserProfile());
  }

  /**
   * Returns the authenticated token, calling updateToken to get a refreshed one if necessary.
   */
  public async getToken() {
    await this.updateToken(10);
    return this._instance.token;
  }

  /**
   * Returns the logged username.
   *
   * @returns
   * The logged username.
   */
  public getUsername() {
    if (!this._userProfile) {
      throw new Error('User not logged in or user profile was not loaded.');
    }

    return this._userProfile.username;
  }

  /**
   * Clear authentication state, including tokens. This can be useful if application
   * has detected the session was expired, for example if updating token fails.
   * Invoking this results in onAuthLogout callback listener being invoked.
   */
  clearToken(): void {
    this._instance.clearToken();
  }

  /**
   * Adds a valid token in header. The key & value format is:
   * Authorization Bearer <token>.
   * If the headers param is undefined it will create the Angular headers object.
   *
   * @param headers
   * Updated header with Authorization and Keycloak token.
   * @returns
   * An observable with with the HTTP Authorization header and the current token.
   */
  public addTokenToHeader(headers: HttpHeaders = new HttpHeaders()) {
    return from(this.getToken()).pipe(
      map((token) =>
        token
          ? headers.set(
            this._authorizationHeaderName,
            this._bearerPrefix + token
          )
          : headers
      )
    );
  }

  /**
   * Returns the original Keycloak instance, if you need any customization that
   * this Angular service does not support yet. Use with caution.
   *
   * @returns
   * The KeycloakInstance from keycloak-js.
   */
  getKeycloakInstance(): Keycloak.KeycloakInstance {
    return this._instance;
  }

  /**
   * Returns the excluded URLs that should not be considered by
   * the http interceptor which automatically adds the authorization header in the Http Request.
   *
   * @returns
   * The excluded urls that must not be intercepted by the KeycloakBearerInterceptor.
   */
  get excludedUrls(): ExcludedUrlRegex[] {
    return this._excludedUrls;
  }

  /**
   * Flag to indicate if the bearer will be added to the authorization header.
   *
   * @returns
   * Returns if the bearer interceptor was set to be disabled.
   */
  get enableBearerInterceptor(): boolean {
    return this._enableBearerInterceptor;
  }

  /**
   * Keycloak subject to monitor the events triggered by keycloak-js.
   * The following events as available (as described at keycloak docs -
   * https://www.keycloak.org/docs/latest/securing_apps/index.html#callback-events):
   * - OnAuthError
   * - OnAuthLogout
   * - OnAuthRefreshError
   * - OnAuthRefreshSuccess
   * - OnAuthSuccess
   * - OnReady
   * - OnTokenExpire
   * In each occurrence of any of these, this subject will return the event type,
   * described at {@link KeycloakEventType} enum and the function args from the keycloak-js
   * if provided any.
   *
   * @returns
   * A subject with the {@link KeycloakEvent} which describes the event type and attaches the
   * function args.
   */
  get keycloakEvents$(): Subject<KeycloakEvent> {
    return this._keycloakEvents$;
  }

  initAuth(): Promise<any> {
    console.log('INIT AUTH');
    var globalP = new Promise<any>((gResolve, gReject) => {
      console.log('GLOBALP');
      var pp = new Promise<any>((resolve, reject) => {
        console.log('PP');
        let finalToken: any = {
          accessToken: null,
          refreshToken: null,
          idToken: null,
        };
        try {

        } catch (e) {
          console.log('error while getting token. resolving to empty', e);
          resolve(finalToken);
        }
        console.log('finalToken', finalToken);
      });

      Preferences.get({key: 'kcTokens'}).then(
        (data) => {
          let finalToken: any = {
            accessToken: null,
            refreshToken: null,
            idToken: null,
          }
          try {
            finalToken = JSON.parse(data.value);
          } catch (e) {
            console.log('error while parsing tokens string from Preferences', e, data.value)
          }
          if (!finalToken) {
            finalToken = {
              accessToken: null,
              refreshToken: null,
              idToken: null,
            }
          }
          console.log('resolved tokens: ', finalToken);
          this.platform.ready().then(
            () => {
              if (
                this.platform.platforms().includes('desktop') ||
                this.platform.platforms().includes('mobileweb')
              ) {
                console.log(
                  '[keycloak] init desktop mode',
                  this.platform.platforms()
                );

                this.init({
                  config: {
                    url: this.envService.keycloak.url,
                    realm: this.envService.keycloak.realm,
                    clientId: this.envService.keycloak.clientId,
                  },
                  initOptions: {
                    adapter: 'default',
                    responseMode: 'query',
                    redirectUri: this.envService.keycloak.redirectUriBrowser,
                    enableLogging: true,
                    token: finalToken.accessToken,
                    refreshToken: finalToken.refreshToken,
                    idToken: finalToken.idToken,
                    onLoad: 'check-sso',
                    silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
                    timeSkew: 0
                  },
                });
              }
              // else if (this.platform.platforms().includes('mobile')) {
              //     console.log('[keycloak] init mobile mode');
              //     this.init({
              //             config: {
              //                 url: this.envService.keycloak.url,
              //                 realm: this.envService.keycloak.realm,
              //                 clientId: this.envService.keycloak.clientId
              //             },
              //             initOptions: {
              //                 adapter: 'capacitor',
              //                 responseMode: 'query',
              //                 redirectUri: 'cdqapp://home?fromLogin=1',
              //                 enableLogging: true,
              //                 token: tokens.token,
              //                 refreshToken: tokens.refreshToken,
              //                 timeSkew: 3600
              //             }
              //         }
              //     );
              // }
              console.log('init keycloak done. resolving');
              gResolve('');
            },
            (err) => {
              console.log('[keycloak init error] tokens not resolved', err);
              gResolve('');
            }
          );
        },
        (error) => {
          console.error('Error getting kcTokens', error);
          gResolve('');
        }
      );
    });
    return globalP;
  }

  loginWithHint(loginHint?: any, url?: any): void {
    if (
      this.platform.platforms().includes('desktop') ||
      this.platform.platforms().includes('mobileweb')
    )
      window.open(
        this._instance.createLoginUrl({maxAge: 0, loginHint: loginHint}),
        '_self'
      );
    else {
      this._instance.login({
        maxAge: 0,
        loginHint: loginHint,
        redirectUri: url,
      });
    }
  }
}
