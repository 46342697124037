import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MenuNavigationComponent } from './components/menu-navigation/menu-navigation.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {AccountType, Configuration} from '@tatynanny/api';
import { NgCalendarModule } from 'ionic2-calendar';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {APP_CONFIG} from './config/app.config';
import {EnvService} from './services/environment/env.service';
import {EnvServiceProvider} from './services/environment/env.service.provider';
import {KeycloakIonicService} from './services/keycloak/keycloak-ionic.service';
import {initializer} from './services/keycloak/keycloak.initializer';
import {KeycloakInterceptorService} from './services/keycloak/keycloak.interceptor.service';


registerLocaleData(localeFr);

@NgModule({
    declarations: [
        AppComponent,
        MenuNavigationComponent,
    ],
    imports: [
        NgCalendarModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
    ],
    exports: [],
    providers: [
        EnvServiceProvider,
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: Configuration,
            useFactory: (envService: EnvService) => {
                console.log('api: ', envService.apiUrl);
                const configBase = {
                    // set configuration parameters here.
                    withCredentials: true,
                    basePath: envService.apiUrl,
                };
                return new Configuration(configBase);
            },
            deps: [EnvService],
            multi: false,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: KeycloakInterceptorService,
            multi: true,
        },
        {
            provide: KeycloakIonicService,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            deps: [KeycloakIonicService, EnvService],
            multi: true,
        },
        {
            provide: APP_CONFIG,
            useValue: {
                userType: AccountType.Babysitter
            }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
