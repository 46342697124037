import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertService } from '../alert/alert.service';
import { ToastController } from '@ionic/angular';
import { EnvService } from '../environment/env.service';
import { KeycloakIonicService } from './keycloak-ionic.service';

@Injectable()
export class KeycloakInterceptorService implements HttpInterceptor {
  /**
   * Constructor
   * @param keycloakService {KeycloakService}
   */
  constructor(
    private keycloakService: KeycloakIonicService,
    private router: Router,
    private alertService: AlertService,
    private toastCtrl: ToastController,
    private envService: EnvService
  ) {}

  /**
   * Interceptor for user token
   * @param request {HttpRequest}
   * @param next {HttpHandler}
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.keycloakService.getKeycloakInstance().authenticated) {
      return this.getUserToken().pipe(
        mergeMap((token) => {
          if (token) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
              },
            });
          }
          return next.handle(request);
        })
      );
    }
    return next.handle(request);
  }

  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | any {

  //   // Exclude interceptor for login request:
  //   // console.log('debug reg intercept search', request.url.search(environment.keycloak.url));
  //   if (request.url.search(this.envService.keycloak.url) === -1 && this.keycloakService.getKeycloakInstance().authenticated) {
  //     return this.getUserToken().pipe(
  //       mergeMap((token) => {
  //         if (token) {
  //           request = request.clone({
  //             setHeaders: {
  //               Authorization: `Bearer ${token}`
  //             }
  //           });
  //         }
  //         return next.handle(request);
  //       }))
  //   }
  // }

  /**
   * Return observable token
   */
  getUserToken() {
    const tokenPromise: Promise<string> = this.keycloakService.getToken();
    const tokenObservable: Observable<string> = from(tokenPromise);
    return tokenObservable;
  }
}
