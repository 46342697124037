import { Injectable } from '@angular/core';
import {FrontbabysitterService, FrontparentService} from '@tatynanny/api';
import { Subscription } from 'rxjs';
import { KeycloakIonicService } from '../keycloak/keycloak-ionic.service';
import { SharedService } from '../shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  isLoggedIn: boolean = false;

  constructor(
    private keycloakIonicService: KeycloakIonicService,
    private sharedService: SharedService,
    private parentService: FrontparentService,
    private candidateService: FrontbabysitterService,
  ) {
  }

  refreshNanny(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.candidateService.apiV1BabysitterProfileLoadProfileGet().forEach(n => {
        resolve(n);
      })
    });
  }

  refreshClient(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.parentService.apiV1ParentProfileLoadProfileGet().forEach(n => {
        resolve(n);
      })
    })
  }

  refresh(): void {
    this.isLoggedIn = this.keycloakIonicService.getKeycloakInstance().authenticated;
  }
}
