import { Injectable } from '@angular/core';
import { KeycloakIonicService } from '../keycloak/keycloak-ionic.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private keycloakService: KeycloakIonicService
  ) { }

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.keycloakService.isLoggedIn().then(connected => {
        if(connected) {
          this.keycloakService.getToken().then(token => {
            if(token != null) {
              resolve(true);
            }
          })
        } else {
          resolve(false);
        }
      })
    })
  }
}
