import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import {SharedService} from './services/shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  providers: [SharedService]
})
export class AppComponent {


  constructor(
    private platform: Platform,
    private sharedService: SharedService
  ) {
    this.initializeApp();
  }

  initializeApp(): void {
		this.platform.ready().then(() => {
      console.log('InitializeApp');
    })
  }
}
