import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private refreshMenuNavigation = new Subject<any>();
  private token = new Subject<any>();
  private badge = new Subject<any>();
  invoiceSubject = new Subject<any>();

  constructor(
  ) {}

  sendBadge() {
    this.badge.next(this.badge);
  }

  getBadge() {
    return this.badge.asObservable();
  }

  sendToken(token: any) {
    console.log('Send Token :', token);
    this.token.next(token);
  }

  getToken() {
    console.log('Get Token');
    return this.token.asObservable();
  }

  sendRefreshMenu() {
    console.log('Send imgComUpdate Event');
    this.refreshMenuNavigation.next('');
  }

  getRefreshMenu(): Observable<any> {
    console.log('Get imgComUpdate Event');
    return this.refreshMenuNavigation.asObservable();
  }

  sendinvoiceStat(data : boolean) {
    console.log('Send invoice Event');
    this.invoiceSubject.next(data);
  }


}
